.plainTextHtml {
  &__header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 0 3.125rem;
    margin: 1.875rem 0 0.625rem;
  }

  &__title {
    margin: 0;
    font-family: var(--font_hind);
    font-size: 1.625rem;
    font-weight: 600;
  }
}
